import * as React from 'react';
import { Connector, useConnect } from 'wagmi';
import { Button } from '~/ui/button';
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle, DialogTrigger } from '~/ui/dialog';
import toast from 'react-hot-toast';
import NavMenuItem from '~/nav-bar/item';
import { faSignIn } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function WalletOptions() {
  const { connectors, connect } = useConnect({
    mutation: {
      onError: (error) => {
        toast.error(error.message);
      },
      onSuccess: () => {
        toast.success('Wallet connected');
      },
    },
  });

  React.useEffect(() => {
    if (connectors.length > 0) {
      for (const connector of connectors) {
        //console.log(connector.name, connector.uid, connector.type);
      }
    }
  }, [connectors]);

  function filterConnectors() {
    if (connectors.length > 0) {
      return connectors.filter((connector) => (connector.name === 'MetaMask' ? connector.type === 'injected' : true));
    }
    return [];
  }

  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <NavMenuItem
        page='Sign-In'
        color='purple'
        className='rounded-none rounded-b-lg'
        onClick={() => setIsOpen(true)}
        icon={<FontAwesomeIcon width={24} height={24} icon={faSignIn} />}
        selected={false}
      />
      <DialogTrigger asChild></DialogTrigger>
      <DialogContent tabIndex={-1} className='gap-8 p-4'>
        <DialogHeader>
          <DialogTitle className='pb-4 rainbowText'>Connect Wallet</DialogTitle>
          <DialogDescription className='flex flex-col gap-4'>
            <span>We support WalletConnect and MetaMask</span>
          </DialogDescription>
        </DialogHeader>
        <div className='grid grid-cols-2 gap-4 place-items-center '>
          {filterConnectors().map((connector) => (
            <WalletOption key={connector.uid} connector={connector} onClick={() => connect({ connector })} />
          ))}
        </div>
      </DialogContent>
    </Dialog>
  );
}

function WalletOption({ connector, onClick }: { connector: Connector; onClick: () => void }) {
  const [ready, setReady] = React.useState(false);

  React.useEffect(() => {
    (async () => {
      const provider = await connector.getProvider();
      setReady(!!provider);
    })();
  }, [connector]);

  return (
    <Button type='button' disabled={!ready} onClick={onClick} variant='tertiary' className='w-fit'>
      {connector.name}
    </Button>
  );
}

export default WalletOptions;
