'use client';
import React, { createContext, useContext, useState, useEffect, useLayoutEffect } from 'react';
import { createPortal } from 'react-dom';

type NavigationBarContent = React.ReactNode;

export enum NavBarContentTypes {
  Immutable = 'immutable',
  Gaming = 'gaming',
}

type NavigationContextType = {
  setNavigationBarContent: (content: NavigationBarContent) => void;
  portalElement: HTMLElement | null;
  navigationBarPortalElement: HTMLElement | null;
  setNavigationBarPortalElement: (element: HTMLElement) => void;
  setContentAndClearPortalElement: (content: NavBarContentTypes) => void;
  setPortalElement: (element: HTMLElement) => void;
  content: NavBarContentTypes;
  setContent: (content: NavBarContentTypes) => void;
  sheetOpen: boolean;
  setSheetOpen: (open: boolean) => void;
};

const NavigationContext = createContext<NavigationContextType>({
  setNavigationBarContent: () => { },
  portalElement: null,
  navigationBarPortalElement: null,
  setNavigationBarPortalElement: () => { },
  setContentAndClearPortalElement: () => { },
  setPortalElement: () => { },
  content: NavBarContentTypes.Immutable,
  setContent: () => { },
  sheetOpen: false,
  setSheetOpen: () => { },
});

export const useNavigationContext = () => useContext(NavigationContext);


export const NavigationProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [navigationBarContent, setNavigationBarContent] = useState<NavigationBarContent>(null);
  const [portalElement, setPortalElement] = useState<HTMLElement | null>(null);
  const [content, setContent] = useState<NavBarContentTypes>(NavBarContentTypes.Immutable);
  const [navigationBarPortalElement, setNavigationBarPortalElement] = useState<HTMLElement | null>(null);
  function setContentAndClearPortalElement(content: NavBarContentTypes) {
    setContent(content);
  }
  const [sheetOpen, setSheetOpen] = useState(false);

  const value = {
    setNavigationBarContent,
    portalElement,
    setPortalElement,
    content,
    setContent,
    navigationBarPortalElement,
    setNavigationBarPortalElement,
    setContentAndClearPortalElement,
    sheetOpen,
    setSheetOpen,
  } as NavigationContextType;

  return (
    <NavigationContext.Provider value={value}>
      {children}
      <GlobalNavigationBar content={navigationBarContent} />
    </NavigationContext.Provider>
  );
};


type GlobalNavigationBarProps = {
  content: React.ReactNode;
}

export function GlobalNavigationBar({ content }: GlobalNavigationBarProps): React.ReactPortal | null {
  const { portalElement } = useNavigationContext();

  if (!portalElement || !content) return null;

  return createPortal(
    <div className="flex items-center justify-between gap-4 px-4 py-2 overflow-hidden border-t border-gray-900 bg-gray-950">
      {content}
    </div>,
    portalElement,
  );
}





