'use client'
import { usePathname } from "next/navigation";
import PopupDialog from "./first";

function TutorialDialog() {

  const pathname = usePathname();

  if (pathname === '/gaming') {
    return null;
  } else {
    return <PopupDialog />;
  }

}

export default TutorialDialog;
