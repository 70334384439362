'use client';

import React, { PropsWithChildren } from 'react';
import { useWindowSize } from 'usehooks-ts';
import { bottomBarPortalID } from 'utils/portals';
import ConditionalBackground from '~/ui/conditional-bg';
import NavBarContent from '~/nav-bar/content';
import TopBar from '~/nav-bar/nav-bar';
import { NavBarContentTypes, useNavigationContext } from 'providers/bottom-navigation';
import { createPortal } from 'react-dom';

function LayoutContent({ children }: PropsWithChildren) {
  const { width } = useWindowSize();
  const mobile = width < 1024;

  return (
    <div className="flex flex-col w-screen h-screen overscroll-none">
      <TopBar />
      {!mobile && <DesktopLayoutContent>{children}</DesktopLayoutContent>}
      {mobile && <MobileLayoutContent>{children}</MobileLayoutContent>}
      <ConditionalBackground />
    </div>
  );
}

function DesktopLayoutContent({ children }: PropsWithChildren) {
  const { setPortalElement } = useNavigationContext();

  const { setNavigationBarPortalElement, navigationBarPortalElement, content } = useNavigationContext();

  const contentPortalRefCallback = (element: HTMLDivElement | null) => {
    if (element) {
      setNavigationBarPortalElement(element);
    }
  };
  const portalRefCallback = (element: HTMLDivElement | null) => {
    if (element) {
      setPortalElement(element);
    }
  };

  return (
    <div className="flex h-full overflow-hidden overscroll-none">
      <div className="flex relative flex-col w-full h-full overflow-y-auto overflow-x-hidden overscroll-none">
        {children}
        <div className="block mt-auto" id={bottomBarPortalID} ref={portalRefCallback}></div>
      </div>
      <div className="px-4 border-l border-purple-700 bg-primary">
        <div className="block h-full mt-auto" id={'nav-bar-content'} ref={contentPortalRefCallback}></div>
        {(content === NavBarContentTypes.Immutable && navigationBarPortalElement) && createPortal(<NavBarContent />, navigationBarPortalElement)}
      </div>
    </div>
  );
}

function MobileLayoutContent({ children }: PropsWithChildren) {
  const { setPortalElement } = useNavigationContext();

  const portalRefCallback = (element: HTMLDivElement | null) => {
    if (element) {
      setPortalElement(element);
    }
  };

  return (
    <>
      <div className="relative flex flex-col h-full overflow-y-auto border-t border-gray-700 overscroll-none">
        {children}
      </div>
      <div className="block" id={bottomBarPortalID} ref={portalRefCallback}></div>
    </>
  );
}

export default LayoutContent;

